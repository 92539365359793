import React from 'react';

import ClearlyLogo from '../images/partners/clearly-logo.png';
import StarStableLogo from '../images/partners/starstable-logo.png';
import StaccLogo from '../images/partners/stacc-logo.png';
import RideSumLogo from '../images/partners/ridesum-logo.png';
import LapointLogo from '../images/partners/lapoint-logo.png';
import BeyondFramesLogo from '../images/partners/beyondframes-logo.png';
import SleipLogo from '../images/partners/sleip-logo.png';
import LolaLykkeLogo from '../images/partners/lolalykke-logo.png';
import LucaLogo from '../images/partners/luca-logo.png';
import MePlusLogo from '../images/partners/meplus-logo.png';
import MoreSailingLogo from '../images/partners/moresailing-logo.png';
import { StaticQuery, graphql } from 'gatsby';

import Row from './row';

class Partners extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            clearly: file(relativePath: { eq: "partners/clearly.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            starstable: file(relativePath: { eq: "partners/starstable.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            stacc: file(relativePath: { eq: "partners/stacc.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            ridesum: file(relativePath: { eq: "partners/ridesum.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            lapoint: file(relativePath: { eq: "partners/lapoint.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            beyondframes: file(
              relativePath: { eq: "partners/beyondframes.jpg" }
            ) {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            lolalykke: file(relativePath: { eq: "partners/lolalykke.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            sleip: file(relativePath: { eq: "partners/sleip.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            moresailing: file(
              relativePath: { eq: "partners/moresailing.jpg" }
            ) {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            luca: file(relativePath: { eq: "partners/luca.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            meplus: file(relativePath: { eq: "partners/meplus.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        `}
        render={(data) => (
          <section>
            <Row
              logo={BeyondFramesLogo}
              image={data.beyondframes.childImageSharp.fluid}
              backgroundColor="#F1D1F5"
              link="https://beyondframes.com/"
              content={
                <>
                  Beyond Frames strives to become one of the go-to publishing
                  partners for current and emerging XR-studios (AR, VR) around
                  the world. The studios that Beyond Frames owns or partners
                  with move the bar of innovative gameplay, creative
                  storytelling and technical advancements in the XR field.
                </>
              }
            />
            <Row
              logo={SleipLogo}
              imageLeft={true}
              image={data.sleip.childImageSharp.fluid}
              backgroundColor="#B6C7A4"
              link="https://sleip.ai/"
              content={
                <>
                  Sleip supports the health and athletic performance for
                  thousands of horses globally, by leveraging the latest
                  Computer Vision technology to detect lameness via smartphones.
                  Sleip is a global leader pioneering the field, dedicated to
                  creating a technology for everyday use that is fast, easy and
                  reliable. Sleip’s customers are equine veterinary clinics.
                </>
              }
            />
            <Row
              logo={MoreSailingLogo}
              image={data.moresailing.childImageSharp.fluid}
              backgroundColor="#BBDBDC"
              link="https://www.moresailing.se/"
              content={
                <>
                  More Sailing was founded on the idea that everyone can sail.
                  The company specializes in sailing trips in the Mediterranean
                  and the Caribbean, offering everything from family-friendly
                  holiday sailings to conference trips and Atlantic voyages.
                  They also rent and sell sailboats. After increasing its
                  turnover ninefold in the past five years, from EUR 1.5m to EUR
                  14m, More Sailing is taking its travel concept to more markets
                  in Europe.
                </>
              }
            />
            <Row
              logo={LolaLykkeLogo}
              imageLeft={true}
              image={data.lolalykke.childImageSharp.fluid}
              backgroundColor="#D2BCB5"
              link="https://lolalykke.com/"
              content={
                <>
                  Lola & Lykke aim to help every mother enjoy a happy and
                  healthy motherhood by creating innovative, technology-driven
                  products that are scientifically proven to improve the health
                  and wellness of pregnant and postpartum women.
                </>
              }
            />
            <Row
              logo={LapointLogo}
              image={data.lapoint.childImageSharp.fluid}
              backgroundColor="#BDCEDB"
              link="https://www.lapointcamps.com/"
              content={
                <>
                  Lapoint is all about good times and a free-spirited lifestyle
                  around surfing, yoga & traveling. At the core of every
                  experience Lapoint creates are social connections: meet
                  interesting people, see amazing places, learn new skills, and
                  have the time of your life. Lapoint currently runs 10 camps
                  around the world, and always looking for new spots with great
                  surf and the right vibe.
                </>
              }
            />
            <Row
              logo={RideSumLogo}
              image={data.ridesum.childImageSharp.fluid}
              imageLeft={true}
              backgroundColor="#ECF1E9"
              link="https://www.ridesum.se/"
              content={
                <>
                  Ridesum wants to revolutionize the way riders train and
                  trainers teach, to the benefit of the horse, environment,
                  rider and trainer. Ridesum is your all-in-one equestrian
                  companion to take your riding to the next level.
                </>
              }
            />
            <Row
              logo={LucaLogo}
              image={data.luca.childImageSharp.fluid}
              backgroundColor="#EDF1F4"
              link="https://www.luca.se/"
              content={
                <>
                  LUCA was founded on the principle that privately held business
                  deserve access to expert financial strategies that drive
                  growth, optimize performance, and navigate the complexities of
                  the market. Founded in 2024, Luca has established operations
                  in Gothenburg and Stockholm, with ambitious plans to expand
                  their presence across key locations in Sweden.
                </>
              }
            />
            <Row
              logo={MePlusLogo}
              image={data.meplus.childImageSharp.fluid}
              imageLeft={true}
              backgroundColor="#D2BCB5"
              link="https://www.me-plus.co/sv/"
              content={
                <>
                  The me+ Company’s team of experienced and dedicated Executive
                  Assistants provides the organizational support you need to
                  boost both your personal performance and the overall success
                  of your business. They help optimize your administrative
                  function and offload the daily burden, so you can focus on the
                  core business.
                </>
              }
            />
            <Row
              logo={StaccLogo}
              image={data.stacc.childImageSharp.fluid}
              backgroundColor="#EFEFEF"
              link="https://stacc.com/"
              content={
                <>
                  STACC is a Nordic software company delivering financial
                  software and services, with 250+ employees serving more than
                  280 customers within the banking and finance industry,
                  insurance, pension funds, investment companies and the public
                  sector in the Nordic regions. In 2022 Stacc entered the
                  Swedish market by acquiring The Farm Interactive AB, owned and
                  founded by Red Six.
                </>
              }
            />
            <Row
              logo={ClearlyLogo}
              imageLeft={true}
              image={data.clearly.childImageSharp.fluid}
              backgroundColor="#B6C7A4"
              link="https://clearlyab.se/"
              content={
                <>
                  Clearly is committed to helping entrepreneurs and businesses
                  in achieving their goals by offering modern financial and
                  accounting services, Clearly’s talented team provides
                  personal, professional and efficient support to CEOs and
                  management teams.
                </>
              }
            />
            <Row
              logo={StarStableLogo}
              image={data.starstable.childImageSharp.fluid}
              backgroundColor="#FBC1CB"
              link="https://www.starstable.com/"
              content={
                <>
                  Star Stable is an online multiplayer horse game based on an
                  epic story of good and evil. StarStable is loved by millions
                  of girls and boasts a predominantly female player base — a
                  rarity in the gaming industry. With its commitment to inspire
                  sisterhood and foster inclusivity, StarStable is transforming
                  the gaming industry into a more open-minded environment.
                </>
              }
            />
          </section>
        )}
      />
    );
  }
}

export default Partners;
