import React from 'react';
import PropTypes from 'prop-types';
import { MobileRule } from '../constants';
import Img from 'gatsby-image/withIEPolyfill';
class Row extends React.Component {
  render() {
    const {
      placeholderText,
      logo,
      image,
      imageLeft,
      link,
      content,
      top,
      backgroundColor,
    } = this.props;

    return (
      <div
        css={{
          backgroundColor,
          display: 'flex',
          flexDirection: imageLeft ? 'row-reverse' : 'row',
          textAlign: 'center',
          [MobileRule]: {
            flexDirection: 'column-reverse',
          },
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '50%',
            padding: '6rem 2rem',
            [MobileRule]: {
              width: '100%',
              padding: '3rem 2rem',
            },
          }}
        >
          {placeholderText ? (
            <p>{placeholderText}</p>
          ) : (
            <img
              src={logo}
              css={{
                maxWidth: '30%',
                maxHeight: '130px',
                marginBottom: '5rem',
                [MobileRule]: { maxWidth: '300px', marginBottom: '2rem' },
              }}
            />
          )}
          <p
            css={{
              fontSize: '20px',
              margin: 0,
              [MobileRule]: { fontSize: '14px' },
            }}
          >
            {content}
          </p>
          {link && (
            <a
              css={{
                fontSize: '20px',
                color: '#E03122',
                fontWeight: 'bold',
                textDecoration: 'underline',
                marginTop: '5rem',
                [MobileRule]: { fontSize: '15px', marginTop: '2rem' },
              }}
              target="_blank"
              href={link}
              rel="noopener noreferrer"
            >
              visit website
            </a>
          )}
        </div>
        <div
          css={{
            width: '50%',
            [MobileRule]: {
              width: '100%',
            },
          }}
        >
          <Img
            css={{ height: '100%', margin: 0 }}
            fluid={image}
            fadeIn={false}
            objectFit="cover"
            objectPosition={top ? '50% 0' : '50% 50%'}
          />
        </div>
      </div>
    );
  }
}
Row.propTypes = {
  content: PropTypes.object,
  imageLeft: PropTypes.bool,
  link: PropTypes.string,
  image: PropTypes.object,
  logo: PropTypes.string,
  placeholderText: PropTypes.string,
  top: PropTypes.bool,
  backgroundColor: PropTypes.string,
};
export default Row;
