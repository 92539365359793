import React from 'react';
import PropTypes from 'prop-types';
import { MobileRule } from '../constants';

class ApproachPoint extends React.Component {
  render() {
    return (
      <div css={{ textAlign: 'center' }}>
        <img
          css={{ height: '70px', marginBottom: '2rem' }}
          src={this.props.logo}
        />
        <h4
          css={{
            fontSize: '25px',
            lineHeight: '30px',
            color: '#FFFFFF',
            marginBottom: '1.5rem',
            textTransform: 'uppercase',
            [MobileRule]: { fontSize: '20px', lineHeight: '25px' },
          }}
        >
          {this.props.title}
        </h4>
        <p
          css={{
            fontSize: '20px',
            margin: 0,
            [MobileRule]: { fontSize: '15px' },
          }}
        >
          {this.props.content}
        </p>
      </div>
    );
  }
}
ApproachPoint.propTypes = {
  logo: PropTypes.string,
  title: PropTypes.object,
  content: PropTypes.string,
};
export default ApproachPoint;
