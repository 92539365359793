import React from 'react';
import Logo from '../images/logos/redsix.png';
import { MobileRule } from '../constants';
import SEO from './seo';
import PropTypes from 'prop-types';

class Header extends React.Component {
  render() {
    return (
      <header
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '4.5rem 2rem 4.5rem',
          maxWidth: '1200px',
          margin: '0 auto',
          [MobileRule]: {
            padding: '2rem 2rem 3.5rem',
          },
        }}
      >
        {!this.props.skipSeo && (
          <SEO
            title={this.props.siteTitle}
            keywords={[
              `Funding`,
              `Leadership`,
              `Finance`,
              `Marketing`,
              `Strategy development`,
              `Business model optimization`,
              `Corporate governance`,
            ]}
          />
        )}
        <img
          src={Logo}
          css={{
            height: '122px',
            marginBottom: '4.5rem',
            [MobileRule]: { height: '87px', marginBottom: '3rem' },
          }}
        />
        <h1
          css={{
            textAlign: 'center',
            fontSize: '35px',
            lineHeight: '60px',
            margin: 0,
            [MobileRule]: { fontSize: '20px', lineHeight: '35px' },
          }}
        >
          <span css={{ color: '#E03122' }}>Red Six</span> backs companies that
          bring people joy and leave a positive mark on the world.
        </h1>
        <h2
          css={{
            textAlign: 'center',
            fontSize: '25px',
            lineHeight: '35px',
            margin: 0,
            fontWeight: 500,
            marginTop: '2rem',
            [MobileRule]: {
              fontSize: '15px',
              lineHeight: '20px',
              marginTop: '1rem',
            },
          }}
        >
          Since 2006, we have helped companies in numerous sectors including
          gaming, fintech, AI, equine tech, adventure travel, and many other
          fields, grow into impactful global brands.
        </h2>
        <span
          css={{
            color: '#E03122',
            margin: '2rem 0',
            fontWeight: 500,
            fontSize: '35px',
            [MobileRule]: { fontSize: '25px' },
          }}
        >
          /
        </span>
        <a
          href="mailto:info@redsix.se"
          css={{
            fontSize: '30px',
            color: '#E03122',
            fontWeight: 'bold',
            textDecoration: 'underline',
            [MobileRule]: { fontSize: '15px' },
          }}
        >
          contact us
        </a>
      </header>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  skipSeo: PropTypes.bool,
};

export default Header;
