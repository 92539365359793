import React, { useState } from 'react';
import { TabletRule } from '../constants';
import { StaticQuery, graphql } from 'gatsby';

const Testimonial = ({ testimonial }) => (
  <div
    css={{
      width: '100%',
      display: 'flex',
      alignItems: 'start',
      gap: '6rem',
      [TabletRule]: { flexDirection: 'column', gap: '2rem' },
    }}
  >
    <img
      css={{
        width: '33%',
        margin: 0,
        [TabletRule]: { width: 'auto', height: '300px', maxWidth: '100%' },
      }}
      src={testimonial.image.src}
    />
    <div css={{ display: 'flex', flex: '1', flexDirection: 'column' }}>
      <p
        css={{
          color: '#E03122',
          fontSize: 'clamp(1.5rem, 2.5vw, 2.5rem)',
          lineHeight: 'clamp(2rem, 3.5vw, 3.75rem)',
          fontWeight: 500,
          margin: 0,
          [TabletRule]: { fontSize: '20px' },
        }}
      >
        ”{testimonial.message}”
      </p>
      <span
        css={{
          color: '#E03122',
          margin: '3rem 0',
          fontWeight: 500,
          fontSize: '35px',
          [TabletRule]: { fontSize: '25px', margin: '2rem 0' },
        }}
      >
        /
      </span>
      <p
        css={{
          fontSize: '25px',
          lineHeight: '38px',
          color: '#E03122',
          margin: 0,
          [TabletRule]: { fontSize: '18px', lineHeight: '20px' },
        }}
      >
        {testimonial.name}
        <br />
        {testimonial.company}
      </p>
    </div>
  </div>
);

const Testimonials = () => {
  const [current, setCurrent] = useState(0);

  return (
    <StaticQuery
      query={graphql`
        query {
          lolalykkelaura: file(
            relativePath: { eq: "testimonials/lolalykke-laura.jpg" }
          ) {
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          moresailingeinar: file(
            relativePath: { eq: "testimonials/moresailing-einar.png" }
          ) {
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      `}
      render={(data) => {
        const testimonials = [
          {
            image: data.lolalykkelaura.childImageSharp.fluid,
            name: 'Laura McGrath',
            company: 'Co-Founder Lola & Lykke',
            message:
              'Being a brand with purpose is crucial for us - our entire business is underpinned by a deep belief in championing women’s causes. Red Six supports every aspect of our organization and enables us to grow as a positive force in society.',
          },
          {
            image: data.moresailingeinar.childImageSharp.fluid,
            name: 'Einar Halldin',
            company: 'CEO & Co-Founder, More Sailing',
            message:
              'We’re on a very exciting journey of taking our travel concept abroad. Red Six has a proven track record of scaling brands from local to global, but most importantly they share our values and vision of what More Sailing can become.',
          },
        ];

        const handleNext = () => {
          setCurrent(current === testimonials.length - 1 ? 0 : current + 1);
        };

        return (
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: '#D6D6D6',
              position: 'relative',
              padding: '8rem 10rem 9rem',
              [TabletRule]: { padding: '5em 5rem 5em 2rem' },
            }}
          >
            <Testimonial testimonial={testimonials[current]} />
            {testimonials.length > 1 && (
              <div
                css={{
                  position: 'absolute',
                  top: '50%',
                  right: 0,
                  transform: 'translateY(-50%)',
                  marginRight: '2rem',
                  background: 'transparent',
                  cursor: 'pointer',
                  [TabletRule]: {
                    marginRight: '1rem',
                  },
                }}
                onClick={handleNext}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 42.32 22.34"
                  css={{
                    width: '60px',
                    color: '#FFFFFF',
                    display: 'block',
                    transform: 'rotate(-90deg)',
                    [TabletRule]: { width: '64px', height: '64px' },
                  }}
                  stroke="currentColor"
                  fill="currentColor"
                >
                  <path d="M21.15 22.34.3 1.71C-.09 1.32-.1.69.3.3c.39-.39 1.02-.4 1.41 0l19.44 19.24L40.61.29c.39-.39 1.03-.38 1.41 0 .39.39.38 1.03 0 1.41L21.15 22.33Z" />
                </svg>
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default Testimonials;
