/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import './layout.css';
import Header from './header';
import Partners from './partners';
import Approach from './approach';
// import Support from './support';
// import Team from './team';
// import Social from './social';
// import Jobs from './jobs';
import Testimonials from './testimonials';
import Footer from './footer';

import 'typeface-poppins';

const Layout = (props) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <div>
        <Header
          skipSeo={props.skipSeo}
          siteTitle={data.site.siteMetadata.title}
        />
        <Partners />
        <Approach />
        <Testimonials />
        {/* <Support /> */}
        {/* <Team /> */}
        {/* <Social /> */}
        {/* <Jobs /> */}
        <Footer />
      </div>
    )}
  />
);
Layout.propTypes = {
  skipSeo: PropTypes.bool,
};
export default Layout;
